/////////////////////////
// Navigation
/////////////////////////

.site-navigation {
    width: 250px;
    position: fixed;
    top: 81px;
    bottom: 0;
    left: 0;
    z-index: 1002;

    &:before {
        content: "";
        width: 80px;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        border-right: solid 1px $color-primary--darker;
        z-index: -1;
    }

    &>ul {
        list-style: none;
        margin: 1rem 0;
        padding: 0;

        &>li {
            position: relative;

            &+li {
                margin-top: .5rem;
            }

            &.disabled {
                pointer-events: none;
                user-select: none;
                opacity: .5;
            }

            // & + li {
            //     .block {
            //         &__icon {
            //             border-top: solid 1px $color-secondary--darker;
            //         }
            //     }
            // }

            // &:first-child {
            //     .block {
            //         &__icon {
            //             border-top: solid 1px $color-secondary--darker;
            //         }
            //     }
            // }

            // &:last-child {
            //     .block {
            //         &__icon {
            //             border-bottom: solid 1px $color-secondary--darker;
            //         }
            //     }
            // }

            &:hover {
                .block {
                    // .block__icon {
                    //     &:before {
                    //         width: calc(100% + .25rem);
                    //     }
                    // }

                    .block__text {
                        color: $color-primary;

                        // &:after {
                        //     transform: translateY(-50%) scale(1);
                        // }
                    }
                }
            }
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            position: absolute;
            left: calc(100% + .5rem);
            top: 0;
            box-shadow: 0 0 8px rgba(0, 0, 0, 0.09);
            background: $color-primary--darkest;
            @include radius(0 .25rem .25rem 0);
            min-width: 10rem;
            display: none;

            &:before {
                content: "";
                width: .5rem;
                height: 100%;
                position: absolute;
                right: 100%;
                top: 0;
                bottom: 0;
            }

            &:after {
                content: "";
                width: .25rem;
                height: 100%;
                position: absolute;
                right: 100%;
                top: 0;
                bottom: 0;
                background: $color-primary;
                @include radius(.25rem 0 0 .25rem);
            }

            li {
                position: relative;

                // & + Li {
                //     border-top: 1px solid rgba($color-black, .05);
                // }

                a:not(.stretched-link) {
                    font-weight: $font-weight--600;
                    color: $color-white;
                    padding: 1rem 1.25rem;
                    display: block;
                    @include transition($transition);
                    position: relative;

                    &:hover {
                        color: $color-primary;
                    }

                    &.hasChildren {
                        padding-right: 3rem;

                        &:after {
                            @include font-awesome('\f105');
                            @include font-size(18px);
                            position: absolute;
                            top: 50%;
                            right: 1.25rem;
                            @include translate(0, -50%);
                            color: $color-white;
                        }

                        &:hover {
                            &:after {
                                color: $color-primary;
                            }
                        }
                    }
                }
            }
        }

        li:hover {
            &>ul {
                display: block;
                animation: subnav .25s linear;
            }
        }
    }

    .block {
        display: flex;

        &__icon {
            width: 5rem;
            height: 3rem;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            position: relative;
            z-index: 1;

            svg {
                height: 2.8rem;
                width: auto;

                .shadow {
                    opacity: 0;
                    @include transition($transition);
                }
            }

            // &:before {
            //     content: "";
            //     width: 0;
            //     height: 100%;
            //     position: absolute;
            //     left: 0;
            //     top: 0;
            //     background: $color-secondary--darker;
            //     z-index: -1;
            //     @include transition($transition);
            // }
        }

        &__text {
            font-weight: $font-weight--600;
            color: $color-white;
            flex-grow: 1;
            display: flex;
            align-items: center;
            width: calc(100% - 5rem);
            @include transition($transition);
            position: relative;

            span {
                padding: 0 1rem 0 1.5rem;
                flex-grow: 1;
            }

            // &:after {
            //     content: "";
            //     width: .5rem;
            //     height: .5rem;
            //     @include radius(50%);
            //     position: absolute;
            //     right: 1rem;
            //     top: 50%;
            //     transform: translateY(-50%) scale(0);
            //     background: $color-secondary;
            //     @include transition($transition);
            // }
        }

        &__link {

            &:hover,
            &.active {
                &+.block {
                    .block__icon {
                        // &:before {
                        //     width: calc(100% + .25rem);
                        // }

                        svg {
                            .shadow {
                                opacity: 1;
                            }
                        }
                    }

                    .block__text {
                        color: $color-primary;

                        &:after {
                            transform: translateY(-50%) scale(1);
                        }
                    }
                }
            }
        }

        &__mega {
            padding: 1.25rem;
            min-width: 20rem;

            hr {
                background: $color-primary--darker;
                opacity: 1;
                margin: 1rem 0;
            }

            h6 {
                a {
                    @include font__size(14px);
                    @include line__height(22px);
                    font-weight: $font-weight--600;
                }
            }
        }

        &__projects {
            &>div {
                display: flex;
                align-items: center;
                position: relative;

                &+div {
                    margin-top: .375rem;
                }

                .label {
                    width: 3rem;
                    height: 3rem;
                    @include radius(.5rem);
                    background: $color-primary--darker;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @include font__size(18px);
                    @include line__height(24px);
                    color: $color-white;
                    font-weight: $font-weight--700;
                }

                &>div {
                    padding-left: .75rem;
                    @include font__size(13px);
                    @include line__height(20px);

                    .title {
                        font-weight: $font-weight--600;
                        display: block;
                        @include transition($transition);
                    }

                    .namespace {
                        display: block;
                    }
                }

                &:hover {
                    &>div {
                        .title {
                            color: $color-primary;
                        }
                    }
                }
            }
        }
    }
}