/////////////////////////
// Form
/////////////////////////

.form {
  &-control {
    font-family: $font-primary;
    @include font__size(13px);
    @include line__height(20px); 
    font-weight: $font-weight--500;
    color: $color-white;
    @include radius(0.5rem);
    box-shadow: 0 0 3px $color-primary--lighter;
    background-color: transparent !important;
    border: 1px solid $color-primary--darker;
    outline: none;
    user-select: none;
    padding: 0.75rem 1.25rem;
    height: auto;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    @include transition($transition);

    &:focus {
      background-color: transparent;
      box-shadow: 0 0 6px $color-primary--lighter;
      border-color: $color-primary--darker;
      color: $color-white;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover, 
    &:-webkit-autofill:focus {      
      border: 1px solid $color-primary--darker;
      -webkit-text-fill-color: $color-white;
      -webkit-box-shadow: 0 0 0px 1000px rgba($color-white, 0) inset, 0 0 6px $color-primary--lighter;
      transition: background-color 100000s ease-in-out 0s;
    }

    &::-webkit-input-placeholder {
      color: rgba($color-white, 0.75);
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: rgba($color-white, 0.75);
      opacity: 1;
    }

    &::placeholder {
      color: rgba($color-white, 0.75);
      opacity: 1;
    }

    &:disabled,
    &[readonly] {
      opacity: 1;
      background: transparent;
      user-select: none;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      display: none;
    }

    &::-ms-clear {
      display: none;
      width: 0;
      height: 0;
    }

    &::-ms-reveal {
      display: none;
      width: 0;
      height: 0;
    }
  }

  &-loader {
    position: absolute;
    right: 1.25rem;
    top: 50%;
    @include translate(0,-50%);
    i {
      @include font__size(24px);
      @include line__height(24px);
      color: $color-white;
      display: block;
    }
  }

  &-label {
    @include font__size(13px);
    @include line__height(20px);
    font-weight: $font-weight--600;
  }

  &-text {
    margin-top: .5rem;
    color: $color-white;
    @include font__size(12px);
    @include line__height(18px);
    font-weight: $font-weight--500;
  }

  &-error {
    color: #ff7683;
    @include font__size(12px);
    @include line__height(18px);
    font-weight: $font-weight--500;
    display: flex;
    align-items: center;
    margin-top: .5rem;
    margin-bottom: 0; 

    &:before {
        content: "";
        width: 16px;
        height: 14px;
        background: url(/assets/images/icon__error.svg) no-repeat 0 0;
        background-size: 16px 14px;
        margin-right: .375rem;
    }
  }

  &-select {
    padding-right: 2.625rem;
    background-position: right 1rem center;
    background-image: url(/assets/images/icon__arrow--down.svg);
    background-size: 10px 6px;
  }

  &-search {
    position: relative;

    .form-control {
      padding-left: 3rem;
    }

    button {
      @include font__size(0px);
      @include line__height(0px);
      @include radius(0.5rem);
      background: none;
      box-shadow: none;
      border: none;
      box-shadow: none;
      outline: none;
      user-select: none;
      cursor: pointer;
      padding: 0;
      height: 100%;
      width: 3rem;
      @include radius(.5rem 0 0 .5rem);
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      @include transition($transition);
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        height: 1rem;
        width: auto;
        fill: $color-primary;
        @include transition($transition);
      }

      &:hover {
        svg {
          fill: $color-primary--darker;
        }
      }
    }
  }

  &-radio {
    position: relative;
    display: inline-flex;
    justify-content: center;
    vertical-align: top;

    [type="radio"] {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 0;
    }

    .icon {
      width: 1.25rem !important;
      height: 1.25rem !important;
      border: solid 2px $color-primary;
      @include radius(50%);
      font-size: 0;
      line-height: 0;
      display: block;
      position: relative;
      overflow: hidden;

      &:after {
        content: "";
        width: 10px;
        height: 10px;
        background: $color-primary;
        @include radius(50%);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(3);
        opacity: 0;
        @include transition($transition);
      }
    }

    &.has-label {
      display: flex;
      align-items: center;

      .form-label {
        margin: 0;
        margin-left: .75rem;
      }
    }

    [type="radio"]:checked ~ .icon {
      &:after {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
      }
    }
  }

  &-checkbox {
    position: relative;
    display: inline-flex;
    vertical-align: top;


    [type="checkbox"] {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 0;
    }

    .icon {
      width: 1.25rem !important;
      height: 1.25rem !important;
      border: solid 2px $color-primary;
      @include radius(.125rem);
      font-size: 0;
      line-height: 0;
      display: block;
      position: relative;
      overflow: hidden;
      @include transition($transition);

      &:after {
        content: "";
        width: 10px;
        height: 10px;
        background: $color-primary;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(3);
        opacity: 0;
        @include transition($transition);
      }
    }

    &.has-label {
      display: flex;
      align-items: center;

      .form-label {
        margin: 0;
        margin-left: .75rem;
      }
    }

    [type="checkbox"]:checked ~ .icon {
      &:after {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
      }
    }
  }

  &-otp {
    display: flex;
    justify-content: center;

    .otp-input {
      font-family: $font-primary;
      width: calc((100% - (.75rem * 5)) / 6) !important;
      height: 4rem !important;
      font-size: 32px !important;
      line-height: 46px !important;
      font-weight: $font-weight--500;
      color: $color-white;
      border-radius: .5rem !important;
      box-shadow: 0 0 3px $color-primary--lighter;
      background-color: transparent !important;
      border: 1px solid $color-primary--darker !important;
      outline: none;
      user-select: none;
      padding: 0.5rem;
      height: auto;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      @include transition($transition);

      &:focus {
        background-color: transparent !important;
        box-shadow: 0 0 9px $color-primary--lighter;
        border-color: $color-primary--darker !important;
        color: $color-white;
      }

      &:not(:last-child) {
        margin-right: .75rem !important;
      }
    }

    &.sml {
      .otp-input {
        width: 3rem !important;
        height: 3rem !important;
        font-size: 1.5rem !important;
        line-height: 30px !important;
        border-radius: .5rem !important;
    
        &:not(:last-child) {
          margin-right: .75rem !important;
        }
      }
    }
  }

  &-date {
    position: relative;

    &:after {
      content: "";
      width: 1rem;
      height: 1rem;
      background: url(/assets/images/icon__calender.svg) no-repeat 0 0;
      background-size: 16px 16px;
      position: absolute;
      right: 1rem;
      top: 50%;
      @include translate(0, -50%);
    }

    &.with-reset {
      .reset {
        position: absolute;
        right: 1rem;
        top: 50%;
        @include translate(0, -50%);
        font-size: 18px;
        color: $color-white;

        &:hover {
          color: $color-primary;
        }
      }

      &:after {
        right: 2.5rem;
      }

      .form-control {
        padding-right: 4.5rem;
      }
    }

    .form-control {
      padding-right: 3rem;
    }
  }

  &-switcher {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;

    label {
      width: 44px;
      height: 22px;
      background: #cccccc;
      @include radius(1.5rem);
      margin: 0;
      font-size: 0;
      line-height: 0;
      position: relative;
      cursor: pointer;
      @include transition($transition);

      &:after {
        content: "";
        width: 1rem;
        height: 1rem;
        @include radius(50%);
        background: $color-white;
        position: absolute;
        left: 0;
        top: 0;
        margin: 3px;
        @include transition($transition);
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
      }
    }

    input {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 1;
      cursor: pointer;
    }

    input:checked ~ label {
      background: $color-primary;
    }

    input:checked ~ label:after {
      left: calc(100% - 22px);
    }
  }

  &-textarea {
    min-height: auto;
    height: 138px;
    overflow-x: hidden;
    overflow-y: auto;
    resize: none;

    &::-webkit-scrollbar {
      width: .375em;
    }

    &::-webkit-scrollbar-track {
      box-shadow: none;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-primary;
      outline: none;
      @include radius(.25rem);
    }
  }

  &-action {
    position: relative;

    & > a {
      position: absolute;
      right: 1rem;
      top: 50%;
      @include translate(0, -50%);

      img,
      svg {
        height: 1.25rem;
        width: auto;
        fill: $color-white;
      }
    }

    .form-control {
      padding-right: 3.25rem;
    }

    &--double {
      .nav {
        position: absolute;
        right: 1rem;
        top: 50%;
        @include translate(0, -50%);
  
        li {
          & + li {
            margin-left: 1rem;
          }
  
          a {
            display: block;
  
            img,
            svg {
              height: 1.25rem;
              width: auto;
              fill: $color-white;
            }
  
            i {
              color: $color-white;
              @include line__height(20px);
              @include font-size(18px);
            }
          }
        }
      }

      .form-control {
        padding-right: 6rem;
      }
    }
  }

  &-loading {
    position: absolute;
    right: 1rem;
    top: 50%;
    @include translate(0, -50%);
    right: 3.25rem;

    i {
      @include font__size(24px);
      color: $color-white;
      display: block;
    }
  }

  &-files {
    display: flex;
    box-shadow: 0 0 3px $color-primary--lighter;
    @include radius(.5rem);

    .names {
      flex-grow: 1;
      @include radius(0.5rem 0 0 0.5rem);
      border: 1px solid $color-primary--darker;
      border-right: none;
      flex-grow: 1;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 0.5rem 1.25rem;

      span {
        border: solid 1px $color-primary;
        background: $color-primary--darkest;
        color: $color-white;
        @include radius(0.25rem);
        padding: 0 0.5rem 0 0;
        @include font__size(12px);
        @include line__height(16px);
        margin: 0.125rem 0.25rem 0.125rem 0;
        display: inline-flex;
        align-items: center;

        &.label {
          border: none;
          background: none;
          @include radius(0);
          padding: 0;
          margin: 0;
          @include font__size(13px);
          @include line__height(20px); 
          font-weight: $font-weight--500;
          color: rgba($color-white, .75);
        }

        a {
          color: $color-primary;
          @include font__size(16px);
          @include line__height(16px);
          margin-right: 0.5rem;
          border-right: solid 1px $color-primary;
          padding: 0.125rem 0.375rem;
          @include transition($transition);

          &:hover {
            color: $color-white;
            background: $color-primary;
          }
        }
      }
    }

    .action {
      position: relative;
      flex-shrink: 0;

      .button {
        @include radius(0 0.5rem 0.5rem 0);
        height: 100%;
        box-shadow: none;
      }

      input[type="file"] {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        opacity: 0;
        z-index: 1;
      }
    }
  }
}

// ng-select

.ng-select {
  @include font__size(13px);
  @include line__height(20px);
  font-weight: $font-weight--500;

  &.ng-select-single {
    .ng-select-container {
      height: 46px;

      &:hover {
        box-shadow: 0 0 6px $color-primary--lighter;
      }

      .ng-value-container {
        .ng-input {
          top: .75rem;
          padding-left: 1.25rem;
          padding-right: calc(2rem + 10px);

          & > input {
            color: $color-white;
          }
        }
      }
    }
  }

  &.ng-select-multiple {
    .ng-select-container {
      .ng-value-container {
        padding-left: 1.25rem;
        padding-top: 2px;
        padding-bottom: 2px;

        .ng-placeholder {
          top: .75rem;
          padding: 0;
        }

        .ng-input {
          padding: 0;

          & > input {
            color: $color-white;
          }
        }

        .ng-value {
          margin: 2px 4px 2px 0;
          @include font__size(12px);
          @include line__height(20px);
          font-weight: $font-weight--500;
          background: $color-primary--darker;
          color: $color-white;
          display: inline-flex;
          vertical-align: top;

          .ng-value-icon {
            @include font__size(16px);
            &.left {
              border-right-color: $color-white;
            }

            &:hover {
              background: $color-primary--darker;
            }
          }
        }
      }
    }
  }

  .ng-select-container {
    font-family: $font-primary;
    color: $color-white;
    @include radius(.5rem);
    border: 1px solid $color-primary--darker;
    min-height: 46px;
    box-shadow: 0 0 3px $color-primary--lighter;
    background: transparent;

    &:hover {
      box-shadow: 0 0 6px $color-primary--lighter;
    }

    .ng-value-container {
      padding-left: 1.25rem;

      .ng-placeholder {
        color: rgba($color-white, 0.75);
      }

      .ng-value {
        .ng-value-label {
          display: flex;
          align-items: center;
        }
      }
    }

    .ng-clear-wrapper {
      width: auto;
      color: $color-primary;
      margin: 0 .5rem;
      display: none;

      .ng-clear {
        display: block;
        @include font__size(18px);
        @include line__height(20px);
      }
    }

    .ng-arrow-wrapper {
      width: 10px;
      margin-right: 1rem;
      margin-left: 1rem;
      padding-right: 0;

      .ng-arrow {
        display: flex;
        align-items: center;
        border: none;
        width: 100%;
        height: 20px;

        &:after {
          content: "";
          width: 10px;
          height: 6px;
          background: url(/assets/images/icon__arrow--down.svg) no-repeat 0 0;
          background-size: 10px 6px;
          display: block;
        }
      }
    }
  }

  &.ng-select-opened {
    &>.ng-select-container {
      border: 1px solid $color-primary--darker;
      box-shadow: 0 0 6px $color-primary--lighter;
      background: transparent;

      &:hover {
        box-shadow: 0 0 6px $color-primary--lighter;
      }

      .ng-arrow {
        top: 0;
      }
    }

    &.ng-select-bottom {
      &>.ng-select-container {
        border-bottom-right-radius: .5rem;
        border-bottom-left-radius: .5rem;
      }
    }

    &.ng-select-top {
      &>.ng-select-container {
        border-top-right-radius: .5rem;
        border-top-left-radius: .5rem;
      }
    }
  }

  &.ng-select-focused {
    &:not(.ng-select-opened) {
      & > .ng-select-container {
        border: 1px solid $color-primary--darker;
        box-shadow: 0 0 6px $color-primary--lighter;
      }
    }
  }

  .ng-dropdown-panel {
    background: $color-primary--darkest;

    &.ng-select-bottom {
      top: calc(100% + .75rem);
      @include radius(.5rem);
      margin: 0;
      border: 1px solid $color-primary--darker;
      box-shadow: 0 0 6px $color-primary--lighter;
      overflow: hidden;
    }

    &.ng-select-top {
      bottom: calc(100% + .75rem);
      @include radius(.5rem);
      margin: 0;
      border: 1px solid $color-primary--darker;
      box-shadow: 0 0 6px $color-primary--lighter;
      overflow: hidden;
    }

    .ng-dropdown-panel-items {
      max-height: 204px;

      &::-webkit-scrollbar {
        width: .375rem;
      }

      &::-webkit-scrollbar-track {
          box-shadow: none;
      }

      &::-webkit-scrollbar-thumb {
          background-color: $color-primary;
          outline: none;
          @include radius(.25rem 0 0 .25rem);
      }

      .ng-option {
        padding: .625rem 1.25rem;
        color: $color-white;
        text-transform: capitalize;
        background: transparent;

        .ng-option-label {
          display: flex;
          align-items: center;
          @include font__size(13px);
          @include line__height(20px);
          font-weight: $font-weight--500;
        }

        &.ng-option-selected,
        &.ng-option-marked {
          color: $color-primary;
          background: none;

          .ng-option-label {
            font-weight: $font-weight--500;
          }
        }

        &.ng-option-marked {
          .ng-option-label {
            font-weight: $font-weight--500;
          }
        }

        &.ng-option-disabled {
          color: $color-white;
          cursor: not-allowed;
        }

        &+.ng-option {
            border-top: 1px solid $color-primary--darker;
        }
      }
    }
  }

  .option {
    &-status {
      width: 1rem;
      height: 1rem;
      margin: 0 .5rem;
      @include radius(.125rem);
    }

    &-social {
      display: flex;
      width: 100%;
      align-items: center;

      .icon {
        width: 1rem;
        height: 1rem;
        margin-right: .75rem;
        display: inline-flex;
        vertical-align: top;
        justify-content: center;

        img {
          height: 1rem;
          width: auto;
        }
      }
    }
  }

  &.ng-select-disabled {
    & > .ng-select-container {
      background: transparent;
      cursor: not-allowed;

      &:hover {
        box-shadow: 0 0 3px $color-primary--lighter;
      }
    }

    .ng-arrow-wrapper {
      display: none;
    }
  }
}
