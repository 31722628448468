/////////////////////////
// Accordion
/////////////////////////

// Dispute Log

.accordion__dispute-log {
    &.accordion {
      .panel {
        margin-bottom: .5rem;
      }

      .accordion {
        &__title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            @include font__size(14px);
            @include line__height(20px);
            color: $color-white;
            font-weight: $font-weight--700;

            .path {
              display: flex;
              align-items: center;
              flex-grow: 1;
              padding-right: 1.5rem;

              & > i {
                margin-right: 1rem;
                @include transition($transition);
                @include font__size(20px);
                color: $color-primary;
              }
            }

            .button {
              width: 28px;
              height: 28px;

              &-group {
                .button + .button {
                  margin-left: .75rem;
                }
              }
            }
        }

        &__content {
          .code {
            width: 100%;
            font-family: $font-code;
            @include font__size(16px);
            @include line__height(22px);

            tr {
              td {
                position: relative;

                &:nth-child(1),
                &:nth-child(2) {
                  background: #fafafa;
                  border-right: solid 1px #f0f0f0;
                  color: rgba($color-black, .3);
                  padding: 0 10px 0 5px;
                  text-align: right;
                  width: 50px;
                }

                &:nth-child(3) {
                  background: #ffffff;
                  color: $color-black;
                  padding: 0 1.5rem;
                }

                span {
                  position: absolute;
                  left: .5rem;
                  top: 0;
                  @include font__size(18px);
                }
              }

              &:first-child,
              &:last-child {
                td {
                  padding: 0;
                  height: 10px;
                  line-height: 0;
                  font-size: 0;
                }
              }

              &.code_old {
                td {
                  background: #fbe9eb;

                  &:nth-child(1),
                  &:nth-child(2) {
                    background: #f9d7dc;
                    border-color: #fac5cd;
                  }

                  span {
                    color: #ae979a;
                  }
                }
              }

              &.code_new {
                td {
                  background: #ecfdf0;

                  &:nth-child(1),
                  &:nth-child(2) {
                    background: #ddfbe6;
                    border-color: #c7f0d2;
                  }

                  span {
                    color: #9bb0a1;
                  }
                }
              }
            }
          }

          .approve-msg {
            background: #fafafa;
            border-bottom: solid 1px #f0f0f0;
            font-family: "Cutive Mono", monospace;
            color: #000;
            padding: .5rem 1.5rem;
            font-size: 13px;
            line-height: 17px;
          }
        }
      }

      .card {
          border: none;
          @include radius(0);
          background: transparent;

          &-header {
              background: linear-gradient(to bottom, #0e3c7c 0%, rgba(14, 60, 124, 0) 100%);
              color: $color-white;
              @include radius(.5rem .5rem 0 0);
              padding: 1rem 1.5rem;
              margin: 0;
              border: none;
          }

          &-body {
              padding: 0;
              background: $color-white;
              @include radius(0 0 .5rem .5rem);
              overflow: hidden;
          }
      }
    }

    .panel {
        &-open {
          .accordion {
            &__title {
              .path {
                & > i {
                  @include rotate(90deg);
                }
              }
            }
          }
        }
      }
  }

// CLI
.accordion__cli {
  &.accordion {
    .accordion {
      &__content {
        padding: 1rem 1.5rem;
        color: $color-black;
      }
    }
  }
}
.custom-size {
  height: 30px; /* adjust as necessary */
  width: 30px;  /* adjust as necessary */
}
// NIST

.accordion__nist{

  .card {
    border: none;
    @include radius(0px);
    background-color: transparent;

    &-header {
      background: none;
      @include radius(0px);
      padding: 0;
      border: none;


    }

    &-body {
      padding: 1rem 0 0;
      //background-color: #1864ab;
    }
  }

  .panel {
    .button--more {
      i {
        @include transition($transition);
      }
    }

    &-open {
      .button--more {
        i {
          @include rotate(90deg);
        }
      }
    }
  }
}




