/////////////////////////
// Pagination
/////////////////////////

.pagination {
    margin: 0;
    padding: 0;
    font-weight: $font-weight--600;
    align-items: center;

    .page-link {
        border: none;
        padding: 0 .5rem;
        //@include radius(.125rem);
        background: none;
        color: $color-white;
        @include transition($transition);
        @include line__height(24px);

        svg {
            height: 1rem;
            width: auto;
            display: block;
            fill: $color-white;
            @include transition($transition);
        }

        &:focus {
            box-shadow: none;
        }

        &:hover {
            color: $color-primary;

            svg {
                fill: $color-primary;
            }
        }
    }

    .page-item {
        & + .page-item {
            margin-left: .25rem;
        }
        &:first-child,
        &:last-child {
            .page-link {
                @include radius(0px);
            }
        }

        &:not(:first-child) {
            .page-link {
                margin: 0;
            }
        }

        &.active,
        &.current {
            .page-link {
                background: none;
                color: $color-primary;

                &:hover {
                    color: $color-primary;
                }
            }
        }

        &.disabled {
            .page-link {
                background: none;
                color: $color-white;
                opacity: .9;
                
                svg {
                    opacity: .75;
                }
            }
        }
    }
}