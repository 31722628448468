/////////////////////////
// Header
/////////////////////////

.site-header {
    border-bottom: solid 1px $color-primary--darker;
    padding: .5rem 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;

    &__logo {
        img {
            height: 4rem;
            width: auto;
            display: block;
        }
    }

    &__aside {
        .dropdown {
            &-toggle {
                display: flex;
                align-items: center;

                .pic {
                    img {
                        height: 2.25rem;
                        width: auto;
                        display: block;
                    }
                }
                .circular-image {
                    border-radius: 50%;
                }

                .name {
                    @include font__size(13px);
                    @include line__height(20px);
                    font-weight: $font-weight--600;
                    text-transform: capitalize;
                    color: $color-white;
                    margin: 0 .5rem 0 .75rem;
                }

                .icon {
                    @include transition($transition);
                    
                    img {
                        height: .375rem;
                        width: auto;
                        display: block;
                    }
                }

                &:after {
                    display: none;
                }
            }

            &-menu {
                border: none;
                color: $color-white;
                font-weight: $font-weight--500;
                @include font__size(13px);
                @include line__height(20px);
                padding: 0;
                min-width: 14rem;
                box-shadow: 0 0 19px rgba(0,0,0,0.09);
                transform: translate3d(0px, 16px, 0px) !important;
                inset: 100% 0px auto auto !important;
                background: $color-primary--darkest;
                @include radius(.25rem);
            }
    
            &-item {
                color: $color-white;
                padding: 0 1rem;
                text-transform: capitalize;
                font-weight: $font-weight--500;
                @include font__size(13px);
                @include line__height(20px);
                display: block;
    
                &:hover,
                &:focus,
                &.active,
                &:active {
                    background: none;
                    color: $color-primary;
                }
            }

            &__code {
                padding: .75rem 1rem;
                border-bottom: solid 1px $color-primary--darker;
            }

            &__name {
                padding: .75rem 1rem;
                display: flex;
                align-items: center;
                border-bottom: solid 1px $color-primary--darker;
                font-weight: $font-weight--600;

                .pic {
                    height: 2.5rem;
                    width: 2.5rem;
                    margin-right: .75rem;
                    @include radius(50%);
                    background: $color-primary;

                    img {
                        width: 100%;
                        height: 100%;
                        display: block;
                    }
                }
            }
        } 

        .show {
            .dropdown {
                &-toggle {
                    .icon {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
}