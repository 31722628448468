//////////////////////////////////
// Home Pages
//////////////////////////////////

.site__home {
    flex-grow: 1;
    width: 100%;
    min-height: 100%;

  .nav {
    justify-content: center;

    li {
        margin: 1rem;
    }

    a {
      display: block;
      position: relative;

      &.disabled {
        pointer-events: none;
        user-select: none;
        opacity: .5;
      }

      .icon,
      .text {
        display: block;
      }

      .icon {
          @include translate(0, 0);
          @include transition($transition);

        svg {
          height: 150px;
          width: auto;
          display: block;

          .shadow {
            opacity: 0;
            @include transition($transition);
          }
        }
      }

      .text {
        text-align: center;
        @include font__size(16px);
        @include line__height(24px);
        font-weight: $font-weight--600;
        color: $color-white;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        @include transition($transition);
        opacity: 0;
        @include translate(0, 1.5rem);
      }

      &:hover {
          .text {
            opacity: 1;
            @include translate(0, 0);
          }
        .icon {
            @include translate(0, -1.5rem);

          svg {
            .shadow {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
