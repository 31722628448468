/////////////////////////
// Typography
/////////////////////////

html {
  @include font__size(16px);
}

body {
  font-family: $font-primary;
  @include font__size(14px);
  @include line__height(22px);
  font-weight: $font-weight--500;
  color: $color-white;
  background-image: radial-gradient(circle at center, #004b8e 0%, #00092b 83%);
}

a {
  color: $color-primary;
  text-decoration: none;
  @include transition($transition);

  &:hover {
    color: $color-primary--darker;
    text-decoration: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-primary;
  margin: 0 0 1rem;
}

h2 {
  @include heading(24px, 32px, $font-weight--700);
  color: $color-primary;
}

h4 {
  @include heading(18px, 26px, $font-weight--600);
}

h5 {
  @include heading(16px, 24px, $font-weight--600);
}

h6 {
  @include heading(14px, 22px, $font-weight--600);
}

.weight-400 {
  font-weight: $font-weight--400 !important;
}

.weight-500 {
  font-weight: $font-weight--500 !important;
}

.weight-600 {
  font-weight: $font-weight--600 !important;
}

.weight-700 {
  font-weight: $font-weight--700 !important;
}

.color-primary {
  color: $color-primary !important;
}

.color-black {
  color: $color-black !important;
}

.color-white {
  color: $color-white !important;
}

.p {
  &--xs {
    @include font__size(12px);
    @include line__height(18px);
    font-weight: $font-weight--700;
  }
  &--l {
    @include font__size(24px);
    @include line__height(32px);
    font-weight: $font-weight--700;
  }
  &--xl {
    @include font__size(60px);
    @include line__height(60px);
    font-weight: $font-weight--700;
  }
}