/////////////////////////
// Mixins
/////////////////////////

@mixin font__size($fontsize) {
    font-size: $fontsize;
    font-size: calculateRem($fontsize);
}

@mixin line__height($lineheight) {
    line-height: $lineheight;
    line-height: calculateRem($lineheight);
}

@mixin heading($fontSize, $lineHeight, $fontWeight) {
    @include font__size($fontSize);
    @include line__height($lineHeight);
    font-weight: $fontWeight;
}

@mixin radius($radius...) {
    -webkit-border-radius: $radius;
    border-radius: $radius;
}

@mixin translate($translateX, $translateY) {
    -webkit-transform: translate($translateX, $translateY);
    transform: translate($translateX, $translateY);
}

@mixin transition($transition...) {
    -webkit-transition: $transition;
    transition: $transition;
}

@mixin rotate($rotate...) {
    -webkit-transform: rotate($rotate);
    transform: rotate($rotate);
}

@mixin scale($scale...) {
    -webkit-transform: scale($scale);
    transform: scale($scale);
}

@mixin skew($skew...) {
    -webkit-transform: skew($skew);
    transform: skew($skew);
}

@mixin animation($animation...) {
    -webkit-animation: $animation;
    animation: $animation;
}

@mixin font-awesome($content) {
    content: $content;
    font-family: $font-awesome;
    font-size: inherit;
    line-height: inherit;
    display: inline-block;
    vertical-align: top;
}

@mixin ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
