/////////////////////////
// Reset
/////////////////////////

* {
    outline: none !important;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

html,
body {
    overflow-x: hidden;
}


