/////////////////////////
// Table
/////////////////////////

.table {
  @include font__size(13px);
  @include line__height(20px);
  color: $color-white;

  tr,
  th,
  td {
    border-color: $color-primary--darker;
  }

  thead {
    background: linear-gradient(to bottom, #0e3c7c 0%, rgba(14, 60, 124, 0) 100%);

    th {
      //background: $color-primary;
      box-shadow: none;
      white-space: nowrap;
      color: $color-white;
      font-weight: $font-weight--700;
      padding: 1rem 1.5rem;
      border: none;
    }
  }

  tbody {
    tr {
      // &:nth-child(even) {
      //   td {
      //     background: #eff1f6;
      //   }
      // }

      // &:nth-child(odd) {
      //   td {
      //     background: $color-white;
      //   }
      // }

      td {
        &.row-title {
          vertical-align: middle;
          text-align: center;
          color: $color-primary;
          font-weight: $font-weight--600;

          .icon {
            img {
              height: 1.5rem;
              width: auto;
              display: block;
            }
          }
        }
      }
    }

    td {
      padding: 1rem 1.5rem;
      border-bottom: solid 1px $color-primary--darker;
    }
  }

  &> :not(:first-child) {
    border: none;
  }

  .disputed {
    background-color: rgba(220,53,69,.25)!important;
  }

  .browser {
    height: 1.5rem;
    width: auto;
  }

  .icon {
    height: 1.5rem;
    width: auto;

    &--sml {
      height: 1rem;
    }
  }

  .action {
    img {
      height: 1.5rem;
      width: auto;
    }

    &--sml {
      img {
        height: 1.25rem;
      }
    }
  }

  a {
    svg {
      fill: $color-primary;
    }

    &:hover {
      svg {
        fill: $color-primary--darker;
      }
    }
  }

  .icon__dispute {
    @include radius(50%);
    position: relative;

    &:before,
    &:after {
      width: .75rem;
      height: .75rem;
      @include radius(50%);
      background-color: $color-white;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      -webkit-animation-delay: 0.9s;
      animation-delay: 0.9s;
      content: "";
      position: absolute;
      box-shadow: 0 0 0 0 rgba($color-primary, .6);
      -webkit-animation: ripple 3s infinite;
      animation: ripple 3s infinite;
      transition: all 0.4s ease;
    }

    &:after {
      -webkit-animation-delay: 0.6s;
      animation-delay: 0.6s;
    }

    @-webkit-keyframes ripple {
      70% {
        box-shadow: 0 0 0 1rem rgba($color-primary, 0);
      }

      100% {
        box-shadow: 0 0 0 0 rgba($color-primary, 0);
      }
    }

    @keyframes ripple {
      70% {
        box-shadow: 0 0 0 1rem rgba($color-primary, 0);
      }

      100% {
        box-shadow: 0 0 0 0 rgba($color-primary, 0);
      }
    }

    svg {
      height: 1rem;
      width: 1rem;
      fill: #dc3545;
      display: block;
      position: relative;
      z-index: 1;
    }
  }

  &-main {
    @include radius(0.5rem);
    //box-shadow: 0 0 19px rgba(0, 0, 0, 0.09);
    overflow: hidden;

    .block__title {
      @include radius(0rem);
    }
  }

  &-bordered {
    tbody {
      tr {
        &:first-child {
          border-top-width: 0px;
        }

        &:last-child {
          border-bottom-width: 0px;
        }

        td {
          &:first-child {
            border-left-width: 0px;
          }

          &:last-child {
            border-right-width: 0px;
          }
        }
      }
    }
  }

  &-vmiddle {

    th,
    td {
      vertical-align: middle;
    }
  }

  &-responsive {
    &::-webkit-scrollbar {
      height: .375em;
    }

    &::-webkit-scrollbar-track {
      box-shadow: none;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-primary--darker;
      outline: none;
      @include radius(0 0 .25rem .25rem);
    }
  }

  &-nowrap {

    th,
    td {
      white-space: nowrap;
    }
  }

  &-groups {
    i {
      @include font__size(16px);
      color: $color-primary;
    }

    .status {
      @include font__size(14px);
      @include line__height(22px);
      font-weight: $font-weight--500;

      a {
        color: $color-white;

        i {
          color: $color-white;
        }

        &:hover {
          color: $color-primary;
        }
      }
    }

    .edit {
      svg {
        height: 1rem;
        width: auto;
        fill: $color-white;
      }

      &:hover {
        svg {
          fill: $color-primary;
        }
      }
    }

    .label {
      min-width: 1.75rem;
      height: 1.75rem;
      text-align: center;
      @include font__size(16px);
      @include line__height(25px);
      padding: 0 0.125rem;
      border: solid 2px $color-primary;
      background: $color-primary--darkest;
      color: $color-white;
      @include radius(0.25rem);
      text-transform: uppercase;
    }
  }

  .status {
    display: inline-flex;
    align-items: center;
    font-weight: $font-weight--500;

    // &:before {

    // }

    &--active {
      color: $success;

      &:before {}
    }

    &--inactive {
      color: $danger;
    }

    &__approve {
      width: 1.25rem;
      height: 1.25rem;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 0 3px $color-primary--lighter;
      border: 1px solid $color-primary--darker;
      color: $color-primary--darker;
      @include radius(.125rem);

      i {
        @include font__size(14px);
        @include line__height(18px);
      }
    }
  }


}
