/////////////////////////
// Animations
/////////////////////////

@keyframes subnav {
    0% {
        transform: translateY(20px);
        opacity: 0;
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}