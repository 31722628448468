//////////////////////////////////
// Profile Dashboard Page
//////////////////////////////////

.profile {
  &__pic {
    position: relative;
    width: 300px;
    height: 300px;
    background: #0d376c;
    @include radius(50%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;

    &:before {
      content: "";
      background: url(/assets/images/icon__avatar.svg) no-repeat 0 0;
      background-size: 125px 150px;
      width: 125px;
      height: 150px;
      position: absolute;
      left: 50%;
      top: 50%;
      @include translate(-50%, -50%);
      color: $color-white;
      z-index: -1;
    }

    & > img {
      width: 300px;
      height: 300px;
      @include radius(50%);
      display: block;
    }

    .upload {
      width: 4rem;
      height: 4rem;
      background: $color-white;
      @include radius(50%);
      position: absolute;
      right: 1rem;
      bottom: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      @include transition($transition);
      cursor: pointer;
      @include scale(.75, .75);

      img {
          height: 2rem;
          width: auto;
      }

      input {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        opacity: 0;
        cursor: pointer;
        z-index: 1;
      }

      &:hover {
        @include scale(1);
      }
    }
  }

  &__graphics {
    max-width: 90%;
  }
}

