//////////////////////////////////
// Authentication Pages
//////////////////////////////////

.site {
  &__authentication {
    padding: 3rem 0;
    min-height: 100vh;
    width: 100%;
    display: flex;

    form {
      .icon {
        position: absolute;
        left: 1.25rem;
        top: 50%;
        @include translate(0, -50%);

        img {
          height: 1rem;
          width: auto;
          display: block;
        }

        &--lg {
          img {
            height: 1.25rem;
          }
        }
      }

      .form-radio {
        .icon {
          position: static;
          @include translate(0, 0);
        }
      }
      /* Hide the default checkbox */
.form-checkbox input[type='checkbox'] {
  opacity: 0;
  position: absolute;
  width: 0;
  height: 0;
}

/* Create a custom checkbox */
.form-checkbox .icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: transparent;
  border: 2px solid #00aaff; /* Your preferred color */
  border-radius: 50%; /* Makes it look like a radio button */
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: relative;
}

/* Style the icon when the checkbox is checked */
.form-checkbox input[type='checkbox']:checked + .icon {
  background-color: #00aaff; /* Your preferred color */
  border-color: #00aaff;
}

/* Create a dot to represent the selected state */
.form-checkbox input[type='checkbox']:checked + .icon::after {
  content: '';
  width: 8px; /* Smaller circle inside */
  height: 8px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


      .form-checkbox {
        
        .icon {
          position: relative;
          left: 0;
          top: 0;
          @include translate(0, 0);
        }
      }

      .agreement {
        a {
          color: $color-primary;;
          &:hover {
            color: $color-primary;
            text-decoration: underline;
          }
        }
      }
    }

    .form {
      &-control {
        padding-left: 3.5rem;
      }

      &-info {
        color: #ff7683;
        span {
          display: flex;
          align-items: center;

          & + span {
            margin-top: 0.125rem;
          }

          &:before {
            content: "";
            height: 0.75rem;
            width: 0.125rem;
            background: #ff7683;
            margin-right: 0.5rem;
          }
        }
      }
    }
  }

  &__signin {
    .logo {
      display: flex;
      justify-content: center;
      margin-bottom: 1rem;

      img {
        height: 250px;
        width: auto;
      }
    }

    .ng-select {
      .ng-select-container {
        .ng-value-container {
          padding-left: 3.5rem;
        }
      }

      &.ng-select-single {
        .ng-select-container {
          .ng-value-container {
            .ng-input {
              padding-left: 3.5rem;
            }
          }
        }
      }
    }
  }

  &__questionnaire {
    .ng-select {
      .ng-select-container {
        .ng-value-container {
          padding-left: 1.25rem;
        }
      }

      &.ng-select-single {
        .ng-select-container {
          .ng-value-container {
            .ng-input {
              padding-left: 1.25rem;
            }
          }
        }
      }
    }

    .form-control {
      padding-left: 1.25rem;
    }
  }
}
