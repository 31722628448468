/////////////////////////
// Global
/////////////////////////

.site {
  &__container {
    padding-top: 81px;
    min-height: 100vh;
    display: flex;
  }

  &__main {
    width: calc(100vw - 250px);
    margin-left: 250px;
    padding: 2rem;
    padding-top: 2.5rem;
    height: calc(100vh - 81px);
    overflow-x: hidden;
    overflow-y: auto;
  }

  &__maintenance {
    min-height: 100vh;
    display: flex;
    align-items: center;
    padding: 2rem 0;
    text-align: center;

    .logo {
      height: 100px;
      width: auto;
      display: block;
    }
  }
}

.tooltip-inner {
  padding: $tooltip-padding-y $tooltip-padding-x;
  color: $tooltip-color;
  text-align: center;
  background-color: $color-primary--darker;
  @include radius(0.25rem);
  @include font__size(12px);
  @include line__height(16px);
  font-weight: $font-weight--500;
}

.tooltip-inner {
  background-color: $color-primary--darker;
}
.tooltip.bs-tooltip-right .tooltip-arrow::before {
  border-right-color: $color-primary--darker !important;
}
.tooltip.bs-tooltip-left .tooltip-arrow::before {
  border-left-color: $color-primary--darker !important;
}
.tooltip.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: $color-primary--darker !important;
}
.tooltip.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: $color-primary--darker !important;
}

.badge {
  color: $color-white;
  @include font__size(12px);
  @include line__height(18px);
  font-weight: $font-weight--500;
  background: $color-primary--darker;
  padding: .125rem .375rem;
}

.back {
  padding: 0 9px 0 6px;
  height: 1.5rem;
  background: $color-primary--darkest;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 3px $color-primary--lighter;
  border: 1px solid $color-primary--darker;
  @include radius(.25rem);

  img {
    height: .75rem;
    width: auto;
  }

  &:hover {
    box-shadow: 0 0 6px $color-primary--lighter;
  }
}

.status {
  &__active,
  &__inactive,
  &__success,
  &__failed,
  &__processing,
  &__cli {
    display: inline-flex;
    align-items: center;
    font-weight: $font-weight--600;

    &:before {
      margin-right: .5rem;
    }
  }
  &__cli {
    letter-spacing: 1px;
    line-height: 14px;
    padding-top: 4px;
  }
  &__active {
    &:before {
      @include font-awesome('\f00c');
    }
  }
  &__inactive {
    &:before {
      @include font-awesome('\f00d');
    }
  }
  &__success {
    &:before {
      @include font-awesome('\f00c');
    }
  }
  &__failed {
    &:before {
      @include font-awesome('\f00d');
    }
  }
  &__processing {
    &:before {
      @include font-awesome('\f252');
    }
  }
}

.text-underline {
  text-decoration: underline !important;
}
