//////////////////////////////////
// Dashboard Pages
//////////////////////////////////

.block {
  &__title {
    padding: 1rem 1.5rem;
    background: linear-gradient(
      to bottom,
      #0e3c7c 0%,
      rgba(14, 60, 124, 0) 100%
    );
    display: flex;
    align-items: center;
    @include radius(0.5rem 0.5rem 0 0);

    h6 {
      @include font__size(13px);
      @include line__height(20px);
      color: $color-white;
      font-weight: $font-weight--700;
      margin: 0;
    }

    .icon {
      height: 1.25rem;
      width: auto;

      &--sml {
        height: 1rem;
      }
    }
  }

  &__nav {
    background: linear-gradient(
      to bottom,
      #0e3c7c 0%,
      rgba(14, 60, 124, 0) 100%
    );
    display: flex;
    align-items: center;
    @include radius(0.5rem 0.5rem 0 0);
    padding: 1rem 1.5rem;

    li {
      & + li {
        margin-left: 2rem;
      }
    }

    a {
      @include font__size(13px);
      @include line__height(20px);
      color: $color-white;
      font-weight: $font-weight--700;
      display: block;
      margin: 0;
      border: none;

      &.active {
        color: $color-primary;
      }
    }
  }

  &__content {
    padding: 2rem 0 0;
    @include font__size(13px);
    @include line__height(20px);
  }
}

// Billing

.plan {
  @include radius(1.5rem);
  overflow: hidden;
  position: relative;
  z-index: 1;
  border: solid 1px $color-primary--darker;
  box-shadow: 0 0 3px $color-primary--lighter;
  height: 100%;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.7;

  }

  & * {
    color: $color-white;
  }

  &__title {
    @include font__size(20px);
    @include line__height(26px);
    font-weight: $font-weight--500;
    padding: 1rem;
    display: block;
  }

  &__pricing {
    border: dashed 1px $color-white;
    background: $color-primary--darkest;
    border-width: 1px 0;
    padding: 1rem;
    min-height: 108px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    strong {
      @include font__size(48px);
      @include line__height(56px);
      font-weight: $font-weight--700;
      padding-right: 0.5rem;
    }

    span {
      font-weight: $font-weight--500;
      @include line__height(18px);
    }
  }

  &__features {
    padding: 1.5rem 1rem;
    min-height: calc(100% - (58px + 108px + 79px));

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      // min-height: 84px;

      li {
        position: relative;
        padding-left: 1.5rem;

        &:before {
          content: "";
          width: 1rem;
          height: 1rem;
          background: url(/assets/images/icon__tick.svg) no-repeat 0 0;
          background-size: 1rem;
          position: absolute;
          left: 0;
          top: 3px;
        }

        & + li {
          margin-top: 0.375rem;
        }
      }
    }

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
        color: $color-white;
      }
    }
  }

  &__buttons {
    border-top: dashed 1px $color-white;
    padding: 1.5rem 1rem;
    min-height: 79px;

    .p--xs {
      @include line__height(15px);
    }

    a {
      padding: 0.25rem 1rem;

      & + a {
        margin-left: 0.5rem;
      }
    }
  }
}

// Monitor

.monitor {
  h6 {
    display: flex;
    align-items: center;
    color: $color-primary;
    margin: 0 0 1.5rem;

    .icon {
      width: 1.5rem;
      height: auto;
    }
  }

  .nav {
    & > li {
      position: relative;
      display: flex;
      align-items: center;

      & + li {
        margin-top: 1.5rem;
      }

      &.dropdown {
        a {
          position: relative;

          &:after {
            @include font-awesome("\f105");
            position: absolute;
            right: 0;
            top: 50%;
            @include translate(0, -50%);
          }
        }
      }

      input {
        background: none;
        border: none;
        box-shadow: none;
        outline: none;
        color: $color-white;
        @include font__size(12px);
        @include line__height(20px);
        font-weight: $font-weight--500;
        padding: 0;
        width: calc(100% - 3.75rem);
        flex-grow: 1;

        &::-webkit-input-placeholder {
          color: rgba($color-white, 1);
          opacity: 1;
          @include font__size(13px);
        }

        &:-ms-input-placeholder {
          color: rgba($color-white, 1);
          opacity: 1;
          @include font__size(13px);
        }

        &::placeholder {
          color: rgba($color-white, 1);
          opacity: 1;
          @include font__size(13px);
        }
      }

      .reset {
        color: $color-white;
        @include font__size(13px);
        @include line__height(20px);
        font-weight: $font-weight--500;
      }

      .icon {
        width: 1.5rem;
        height: auto;
      }

      a {
        display: flex;
        align-items: center;
        flex-grow: 1;
        color: $color-white;

        &:hover {
          color: $color-primary;
        }
      }
    }

    .dropdown {
      &-menu {
        border: none;
        color: $color-white;
        font-weight: $font-weight--500;
        @include font__size(13px);
        @include line__height(20px);
        padding: 0 0 0 0.25rem;
        min-width: auto;
        width: 300px;
        box-shadow: 0 0 19px rgba(0, 0, 0, 0.09);
        transform: translate3d(16px, 0px, 0px) !important;
        inset: 0px auto auto 100% !important;
        background: $color-primary--darkest;
        @include radius(0.25rem);

        &:after {
          content: "";
          width: 0.25rem;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          background: $color-primary;
          @include radius(0.25rem 0 0 0.25rem);
        }

        ul {
          list-style: none;
          margin: 0;
          padding: 0.5rem 0;
          max-height: 196px;
          overflow-x: hidden;
          overflow-y: auto;

          &::-webkit-scrollbar {
            width: 0.375em;
          }

          &::-webkit-scrollbar-track {
            box-shadow: none;
          }

          &::-webkit-scrollbar-thumb {
            background-color: $color-primary;
            outline: none;
            @include radius(0.25rem);
          }

          li {
            padding: 0.5rem 1rem;

            .form {
              &-checkbox {
                .icon {
                  flex-shrink: 0;
                }
              }

              &-label {
                font-weight: $font-weight--500;
                @include ellipsis;
                flex-grow: 1;
              }
            }
          }
        }
      }
    }
  }

  &__graph {
    border: solid 1px $color-primary--darker;
    box-shadow: 0 0 3px $color-primary--lighter;
    @include radius(0.5rem);
    padding: 1rem;

    a {
      color: $color-white;
      @include transition($transition);

      &:hover {
        color: $color-primary;
      }
    }

    h6 {
      color: $color-white;
      margin: 0 0 1rem;
    }

    .inner {
      background: rgba($color-primary, 0.1);
      height: 200px;
      @include radius(0.25rem);
    }

    .symbol {
      width: 150px;
      height: 150px;
      display: inline-flex;
      vertical-align: top;
    }
  }
}

// Manage/Repo Connection

.repositories {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    position: relative;

    & + li {
      margin-top: 1.5rem;
    }

    input[type="radio"] {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      visibility: hidden;
    }

    label {
      display: block;
      margin: 0;
      background: $color-primary--darker;
      @include radius(2rem);
      @include font__size(14px);
      @include line__height(20px);
      font-weight: $font-weight--600;
      color: $color-white;
      padding: 0.75rem 1.5rem;
      padding-right: 4rem;
      cursor: pointer;
      @include transition($transition);
      position: relative;

      &:after {
        content: "";
        width: 1rem;
        height: 1rem;
        background: url(/assets/images/icon__tick--white.svg) no-repeat 0 0;
        background-size: 1rem;
        position: absolute;
        right: 1.5rem;
        top: 50%;
        @include translate(0, -50%);
        opacity: 0;
        @include transition($transition);
      }
    }

    input[type="radio"]:checked ~ label {
      &:after {
        opacity: 1;
      }
    }
  }
}

// Cart

.cart {
  .block {
    @include radius(0.5rem);
    border: solid 1px $color-primary--darker;
    box-shadow: 0 0 3px $color-primary--lighter;
    min-height: 100%;

    .logo {
      height: 5rem;
      width: auto;
    }
  }

  &__total {
    border-top: dashed 1px $color-white;
  }
}

//agm map
app-monitor-map,
agm-map {
  width: 100%;
}

app-monitor-map {
  display: block;
}

agm-map {
  height: 650px;
}

// NIST

.blocks__nist {
  .block {
    background: rgba($color-white, .15);
    @include radius(.5rem);
    padding: 1rem;
    display: flex;
    align-items: center;

    & + .block {
      margin-top: .375rem;
    }

    .button-group {
      flex-shrink: 0;
      margin-left: 1rem;

      .button {
        & + .button {
          margin-left: .375rem;
        }
      }
    }

    .button--more {
      border: none;
      box-shadow: none;
      outline: none;
      background: none;
      color: $color-white;
      @include font__size(24px);
      @include line__height(44px); 
      font-weight: $font-weight--700;
      padding: 0;

      &:disabled {
        opacity: .5;
        cursor: not-allowed;
      }
    }

    &-uploaded-data {
      border: solid 2px $color-white;
      display: block;
      margin-right: 20px;

      .button-group {
        a {
          color: $color-white;
          @include font__size(12px);
          @include line__height(20px);

          span {
            display: flex;
            justify-content: center;

            i {
              @include font__size(20px);
              @include line__height(28px);
            }
          }

          &:hover {
            color: $color-primary;
          }
        }
      }
    }
  }
}
