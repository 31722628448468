///////////////////////////
// Date Picker
///////////////////////////

.bs-datepicker {
    box-shadow: 0 0 19px rgba(0,0,0,0.09) !important;
    @include radius(.25rem);
    background: $color-white;
    font-weight: $font-weight--500;
    @include font__size(13px);
    @include line__height(20px);


    & &-container {
        padding: 0;
    }

    &-head {
        @include radius(.25rem .25rem 0 0);

        button {
            &:hover {
                background: $color-primary--darkest;
            }
        }
    }

    &-body {
        @include radius(0 0 .25rem .25rem);

        table {
            td {
                color: $color-primary--darkest;

                span.is-other-month {
                    color: rgba($color-primary--darkest, .25);
                }

                &.is-other-month {
                    span {
                        color: rgba($color-primary--darkest, .25);
                    }
                }
            }
        }
    }

    &.theme-green {
        .bs-datepicker-head {
            background: $color-primary--darker;
        }
        .bs-datepicker-body {
            border: none;

            table {
                th {
                    color: $color-primary--darkest;
                    font-weight: $font-weight--600;
                }
                td.week {
                    span {
                        color: $color-primary;
                    }
                }

                td {
                    span.selected {
                        background: $color-primary--darkest;
                        color: $color-white;
                    }
                }
            }
        }
    }
}