/////////////////////////
// Button
/////////////////////////

.button {
    font-family: $font-primary;
    @include font__size(14px);
    @include line__height(20px); 
    font-weight: $font-weight--700;
    text-transform: capitalize;
    box-shadow: none;
    outline: none;
    user-select: none;
    padding: 0.75rem 1.5rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    @include radius(0.5rem);
    @include transition($transition);
    cursor: pointer;

    &.disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    .icon {
        height: 1.25rem;
        width: auto;
        @include transition($transition);
        
        &--left {
            margin-right: .75rem;
        }

        &--right {
            margin-left: .75rem;
        }

        &--arrow {
            height: .375rem !important;
        }

        &--sml {
            height: 1rem;
        }

        &--xsml {
            height: .75rem;
        }
    }

    &--sml {
        @include font__size(13px);
        padding: 0.5rem 1.25rem;
        font-weight: $font-weight--600;

        .icon {
            height: 1rem;
        }
    }

    &--xsml {
        @include font__size(10px);
        @include line__height(16px);
        padding: 0.125rem .5rem;
        font-weight: $font-weight--500;
        @include radius(0.25rem);
    }

    &--primary {
        box-shadow: 0 0 3px $color-white;
        background-color: transparent !important;
        border: 1px solid $color-white;
        color: $color-white;

        .icon {
            fill: $color-white;
        }

        &:hover {
            box-shadow: 0 0 6px $color-white;
            color: $color-white;

            .icon {
                fill: $color-white;
            }
        }
    }

    &--secondary {
        box-shadow: 0 0 3px $color-primary--lighter;
        background-color: transparent !important;
        border: 1px solid $color-primary--darker;
        color: $color-white;

        .icon {
            fill: $color-white;
        }

        &:hover {
            box-shadow: 0 0 6px $color-primary--lighter;
            color: $color-white;

            .icon {
                fill: $color-white;
            }
        }
    }

    &--open {
        box-shadow: none;
        border: none;
        background: none;
        color: $color-primary;
        padding: 0;
        @include font__size(14px);
        @include line__height(20px); 

        .icon {
            fill: $color-primary;
        }

        &:hover {
            color: $color-white;

            .icon {
                fill: $color-white;
            }
        }
    }

    &-group {
        display: flex;
        align-items: center;

        .button + .button {
            margin-left: 1rem;
        }

        a {
            &:not(:first-child) {
                margin-left: 1rem;
            }
        }
    }

    &--icon {
        border: solid 2px $color-primary;
        background: $color-primary--darkest;
        @include radius(.25rem);
        box-shadow: none;
        width: 2rem;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color-white;
        padding: 0;
        @include font__size(16px);
        @include line__height(24px);

        &:hover {
            color: $color-white;
            box-shadow: 0 0 6px $color-white;
        }
    }

    &--nist {
        border: none;
        color: $color-white;
        padding: .25rem;
        position: relative;
        z-index: 9;

        .icon {
            width: 36px;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            @include radius(0.375rem);

            i {
                @include font__size(20px);
            }
        }
        
        span {
            width: 120px;
            text-align: center;
        }

        &.button {
            &--green {
                background: $success;

                .icon {
                    background: darken($success, 10);
                }
            }

            &--skyblue {
                background: $info;

                .icon {
                    background: darken($info, 20);
                }
            }

            &--gray {
                background: $secondary;

                .icon {
                    background: darken($secondary, 20);
                }
            }

            &--red {
                background: $danger;

                .icon {
                    background: darken($danger, 20);
                }
            }
            &--yellow {
                background: $warning;

                .icon {
                    background: darken($warning, 20);
                }
            }
        }
    }
}