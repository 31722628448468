/////////////////////////
// Variables
/////////////////////////

$color-primary: #60c3f1;
$color-primary--darker: #069fe3;
$color-primary--lighter: #00b0fb;
$color-primary--darkest: #0e3c7c;
$color-white: #ffffff;
$color-black: #000000;

$font-primary: 'Exo', sans-serif;
$font-code: 'Cutive Mono', monospace;
$font-awesome: 'FontAwesome';

$font-weight--400: 400;
$font-weight--500: 500;
$font-weight--600: 600;
$font-weight--700: 700;

$transition: all 0.3s ease-in;

$grid-gutter-width: 2rem;