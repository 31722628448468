/////////////////////////
// Modal
/////////////////////////

.modal {
  &-content {
    border: none;
    @include radius(0.5rem);
    box-shadow: 0 0 1.5rem rgba($color-black, 0.15);
    background: $color-primary--darkest;
  }

  &-header {
    padding: 1.5rem 1.5rem 1rem;
    border: none;
    @include radius(0.5rem 0.5rem 0 0);
    background: linear-gradient(
      to bottom,
      #032858 0%,
      $color-primary--darkest 100%
    );

    h4 {
      flex-grow: 1;
      margin-right: 1.5rem;
      display: flex;
      align-items: center;

      img {
        height: 1.25rem;
        width: auto;
        margin-right: 1rem;
      }
    }
  }

  &-title {
    flex-grow: 1;
    display: flex;
    align-items: center;

    img {
      height: 1.5rem;
      width: auto;
      margin-right: 1rem;
    }
  }

  &-close {
    margin-left: auto;
    flex-shrink: 0;

    img {
      height: 1rem;
      width: auto;
      display: block;
    }
  }

  &-body {
    padding: 1rem 1.5rem 1.5rem;
    @include radius(0 0 0.5rem 0.5rem);

    .table {
      thead {
        background: linear-gradient(to bottom, #002355 0%, rgba(14, 60, 124, 0) 100%);
      }
    }
  }
}

// Face Recognition Modal

#modal__faceRecognize {
  .modal {
    &-content {
      background-image: radial-gradient(
        circle at center,
        #004b8e 0%,
        #00092b 83%
      );
      @include radius(0);
    }

    &-body {
      padding: 0;

      .button-group {
        position: absolute;
        bottom: 1.5rem;
        left: 1.5rem;
        right: 1.5rem;
        z-index: 1;

        .button {
          background: $color-primary--darkest !important;
          border: solid 2px $color-primary;
          box-shadow: none;
        }
      }
    }

    &-close {
      position: absolute;
      left: calc(100% + 1.5rem);
      top: 0;
      border: solid 2px $color-primary;
      background: $color-primary--darkest;
      @include radius(0.25rem);
      width: 2.5rem;
      height: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 1rem;
        width: auto;
      }

      @include media-breakpoint-down(md) {
          & {
              left: auto;
              right: 0;
              top: -3.5rem;
              width: 2rem;
            height: 2rem;

            img {
                height: .75rem;
                width: auto;
              }
          }
      }
    }
  }
  .inner {
    position: relative;
    overflow: hidden;
    @include radius(0.5rem);

    video,
    canvas {
      display: block;
      margin: 0;
      width: 100%;
      height: 100%;
    }

    .scan {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;

      &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 5rem;
        background-image: linear-gradient(
          0deg,
          #b9eafc 0%,
          rgba(4, 159, 217, 0) 100%
        );
        transform: translate(0, -80px);
        animation: scanning 1s linear infinite;
        animation-delay: 2s;
      }

      @keyframes scanning {
        0% {
          transform: translate(0, -80px);
        }
        100% {
          transform: translate(0, 600px);
        }
      }

      .corner {
        position: absolute;
        width: 100px;
        height: 100px;
        border-style: solid;
        background: transparent;
        border-color: $color-primary;
        z-index: 1;

        @include media-breakpoint-down(md) {
            & {
                width: 50px;
                height: 50px;
            }
        }

        &__tl {
          left: 0;
          top: 0;
          border-width: 0.5rem 0 0 0.5rem;
        }

        &__tr {
          right: 0;
          top: 0;
          border-width: 0.5rem 0.5rem 0 0;
        }

        &__bl {
          left: 0;
          bottom: 0;
          border-width: 0 0 0.5rem 0.5rem;
        }

        &__br {
          right: 0;
          bottom: 0;
          border-width: 0 0.5rem 0.5rem 0;
        }
      }
    }
  }
}

// Upload Modal

#modal__upload {
  .box-upload {
    position: relative;
    border: dashed 2px $color-primary--darker;
    @include radius(.5rem);
    padding: 1.25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include transition($transition);

    [type="file"] {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 1;
      cursor: pointer;
    }

    &:hover {
      box-shadow: 0 0 6px $color-primary--lighter;
    }
  }
}
