@charset "UTF-8";

@import url('https://fonts.googleapis.com/css2?family=Cutive+Mono&family=Exo:wght@400;500;600;700&display=swap');
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~ngx-bootstrap/datepicker/bs-datepicker.css";
@import "~sweetalert2/src/sweetalert2.scss";

// 1. Utilities
@import 
"assets/sass/utilities/variables", 
"assets/sass/utilities/functions",
"assets/sass/utilities/mixins",
"assets/sass/utilities/animation";

// 2. Vendors
@import 
"assets/sass/vendors/bootstrap",
"assets/sass/vendors/font-awesome";

// 3. Base stuff
@import 
"assets/sass/base/reset", 
"assets/sass/base/typography",
"assets/sass/base/global";

// 4. Components
@import 
"assets/sass/components/form",
"assets/sass/components/button",
"assets/sass/components/table",
"assets/sass/components/pagination",
"assets/sass/components/datepicker",
"assets/sass/components/modal",
"assets/sass/components/tab",
"assets/sass/components/accordion",
"assets/sass/components/progressBar";

// 5. Layout
@import 
"assets/sass/layout/header",
"assets/sass/layout/navigation";

// 6. Pages
@import 
"assets/sass/pages/authentication",
"assets/sass/pages/home",
"assets/sass/pages/dashboard",
"assets/sass/pages/profile";

.at-modalalert{
    width: 100%;
    max-width: 580px !important;
}
.at-formatmodal{
    width: 100%;
    max-width: 430px;
}
.at-versionhistorymodal{
    width: 100%;
    max-width: 1440px;
    .modal-content{
        position: relative;
        padding: 0 265px 0 0;
    }
    .modal-body{position: static;}
}
.at-versionaccordion{
    width: 100%;
    float: left;
    .at-accordiongroup{
        width: 100%;
        float: left;
    }
    .card{
        width: 100%;
        float: left;
        margin: 6px 0;
        background: none;
        .card-header{
            border: 0;
            padding: 0;
            width: 100%;
            float: left;
            .panel-title{
                width: 100%;
                float: left;
                text-decoration: none;
                .accordion-toggle{
                    width: 100%;
                    float: left;
                    .btn-link{
                        padding: 0;
                        width: 100%;
                        float: left;
                        color: #fff;
                        font-size: 12px;
                        text-align: left;
                        font-weight: 600;
                        line-height: 15px;
                        position: relative;
                        text-decoration: none;
                        &:after{
                            top: 4px;
                            width: 5px;
                            left: -22px;
                            height: 5px;
                            content: '';
                            position: absolute;
                            border-radius: 50%;
                            border: 1px solid #fff;
                            background: rgba(255, 255, 255,0.30);
                        }
                        &:focus{box-shadow: none;}
                    }
                }
            }
        }
        .card-body,
        .panel-collapse{
            width: 100%;
            float: left;
        }
        .card-body{padding: 0;}
    }
}

.at-versionbox{
    width: 100%;
    float: left;
    overflow: hidden;
    min-height: 595px;
    background: #fff;
    border-radius: 8px;
}
.at-versionboxhead{
    width: 100%;
    float: left;
    display: flex;
    padding: 10px 20px;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.10);
    justify-content: space-between;
    h4{
        margin: 0;
        font-size: 16px;
        color: #010A29;
        font-weight: bold;
        line-height: 19px;
    }
    span{
        display: block;
        font-size: 14px;
        color: #028C41;
        line-height: 17px;
    }
}
.at-versionboxcontent{
    width: 100%;
    float: left;
    padding: 20px;
    overflow: auto;
    height: calc(100vh - 300px);
}
.at-versionboxcontent::-webkit-scrollbar{width: 0;}
.at-historyarea{
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    padding: 16px;
    overflow: auto;
    max-width: 265px;
    min-height: 768px;
    position: absolute;
    background: #0A224C;
    &::-webkit-scrollbar{width: 0;}
}
.at-historyareahead{
    width: 100%;
    float: left;
    position: relative;
    .btn-close{
        top: 0;
        right: 0;
        padding: 0;
        opacity: 1;
        width: 15px;
        height: 15px;
        color: #fff;
        position: absolute;
        span{
            color: #fff;
            font-size: 25px;
            line-height: 25px;
        }
    }
}
.at-versionaccodionholder{
    width: 100%;
    float: left;
    position: relative;
    padding: 0 0 0 18px;
    h6{
        color: #fff;
        font-size: 12px;
        font-weight: 600;
        line-height: 15px;
        position: relative;
        &:after{
            top: 0;
            width: 5px;
            left: -20px;
            height: 5px;
            content: '';
            position: absolute;
            border-radius: 50%;
            border: 1px solid #fff;
            background: rgba(255, 255, 255,0.30);
        }
    }
    &:after{
        top: 0;
        left: 0;
        width: 1px;
        content: '';
        height: 100%;
        position: absolute;
        background: rgba(217, 217, 217, 0.20);
    }
}
.at-accordioncontent{
    width: 100%;
    float: left;
    padding: 10px 0 0;
    h5{
        color: #fff;
        margin: 0 0 5px;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        span{font-weight: 400;}
    }
}
.at-changetag{ 
    display: flex;
    font-size: 10px;
    max-width: 85px;
    padding: 0 12px;
    min-height: 22px;
    line-height: 22px;
    align-items: center;
    border-radius: 30px;
    justify-content: center;
}
.at-changetag + .at-changetag{margin: 5px 0 0;}
#html-spinner{
    width: 20px;
    height: 20px;
    border: 3px solid #F9F9F9;
    border-top: 3px solid #028c41;
    border-radius: 50%;
  }
  
  #html-spinner, #svg-spinner{
    -webkit-transition-property: -webkit-transform;
    -webkit-transition-duration: 1.2s;
    -webkit-animation-name: rotate;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    
    -moz-transition-property: -moz-transform;
    -moz-animation-name: rotate; 
    -moz-animation-duration: 1.2s; 
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    
    transition-property: transform;
    animation-name: rotate; 
    animation-duration: 1.2s; 
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  
  @-webkit-keyframes rotate {
      from {-webkit-transform: rotate(0deg);}
      to {-webkit-transform: rotate(360deg);}
  }
  
  @-moz-keyframes rotate {
      from {-moz-transform: rotate(0deg);}
      to {-moz-transform: rotate(360deg);}
  }
  
  @keyframes rotate {
      from {transform: rotate(0deg);}
      to {transform: rotate(360deg);}
  }
  .at-accesstokenmodal{
    .modal-content{
        .modal-header{
            .btn-close{background-color: #fff; opacity: 1;}
        }
    }
}
.AtFormateModal {max-width: 700px; width: 100%;}
.at-viewpdfmodal{
    width: 100%;
    max-width: 1200px;
    .modal-body{
        padding: 0;
        background: #fff;
        .at-btnclosemodal{z-index: 99;}
    }
}
.pdfViewer{
    .page,
    .textLayer,
    .canvasWrapper{width: 100% !important;}
}
.at-pdfheadingholder{
    width: 100%;
    float: left;
    h3{
        margin: 0;
        width: 100%;
        float: left;
        font-size: 24px;
        color: #010A29;
        font-weight: bold;
        padding: 20px 30px;
    }
}
.at-jsonviewerarea{
    width: 100%;
    float: left;
}
.ngx-json-viewer{
    // padding: 0 30px;
    overflow: initial !important;
}
.at-btnwithloader{
    gap: 10px;
    .at-tableloader{
        width: auto !important;
        display: flex;
        align-items: center;
        justify-content: center;
        .lds-dual-ring{
            width: 20px;
            height: 20px;
            &:after{
                width: 100%;
                height: 100%;
                border: 4px solid #96A1AF;
                border-color: #96A1AF transparent #96A1AF transparent;
                animation: lds-dual-ring 1.2s linear infinite;
            }
        }
    }
}
.at-tableloader{
    width:100%;
    float: left;
    text-align: center;
    .lds-dual-ring {
        display: inline-block;
        width: 40px;
        height: 40px;
      }
      .lds-dual-ring:after {
        content: " ";
        display: block;
        width: 40px;
        height: 40px;
        margin: 0;
        border-radius: 50%;
        border: 8px solid #fff;
        border-color: #fff transparent #fff transparent;
        animation: lds-dual-ring 1.2s linear infinite;
      }
      @keyframes lds-dual-ring {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
}
.disabled {
    pointer-events: none; /* Disable pointer events to prevent interaction */
    opacity: 0.6; /* Reduces opacity for a faded appearance */
    cursor: not-allowed; /* Change the cursor to 'not-allowed' */
}
.at-wbhookmodalcontent{
    h3{
        width: 100%;
        float: left;
        font-size: 24px;
        color: #010A29;
        font-weight: bold;
    }
    p{
        font-size: 16px;
        margin: 0 0 15px;
        color: #3D3D3D;
        font-weight: 500;
    }
    h4{
        margin: 0;
        font-size: 16px;
        color: #3D3D3D;
        font-weight: bold;
        word-break: break-all;
    }
}

.AtTokenInputs{
    border: 0;
    padding: 0;
    width: auto;
    color: #fff;
    background: none;
}

.removed{
    background-color: red;
}
.added{
    background-color: black;
}
.changed{
    background-color: yellow;
}
.at-versionfilehistory{
    padding: 0;
    width: 100%;
    float: left;
    list-style: none;
    position: relative;
}
.at-versionfilehistory:first-child::after{display: none;}
.at-versionfilehistory li{
    width: 100%;
    float: left;
    color: #373737;
    position: relative;
    font-weight: normal;
    word-wrap: break-word;
    list-style-type: none;
    padding: 3px 0 3px 15px;
}
.at-versionfilehistory li:first-child,
.at-versionfilehistory li:nth-child(2),
.at-versionfilehistory li:nth-child(3),
.at-versionfilehistory li:nth-child(4){padding: 3px 0;}
.at-versionfilehistory:after{
    top: 10px;
    left: 0;
    width: 0;
    height: 0;
    content: "";
    position: absolute;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 8px solid #6f4394;
}
.at-versionfilehistory li:first-child:after,
.at-versionfilehistory li:nth-child(2):after,
.at-versionfilehistory li:nth-child(3):after,
.at-versionfilehistory li:nth-child(4):after{display: none;}
.at-versionfilehistory li strong{
    color: #6f4394;
    font-weight: normal;
}
.at-versionfilehistory li span{color: #000;}
.at-versionfilehistory li ul{
    width: 100%;
    float: left;
    position: relative;
}

.at-versionfilehistory li ul:after{
    top: 10px;
    left: 0;
    width: 0;
    height: 0;
    content: "";
    position: absolute;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 8px solid #6f4394;
}
.zeroopacity{
    opacity: 0;
}

.at-versionfilehistory li ul li{
    position: relative;
    padding: 3px 0 3px 15px !important;
}
.at-versionfilehistory li > ul > li:first-child:after,
.at-versionfilehistory li > ul > li:nth-child(2):after,
.at-versionfilehistory li > ul > li:nth-child(3):after,
.at-versionfilehistory li > ul > li:nth-child(4):after{display: block;}
.at-versionfilehistory li ul li ul li:after{display: none;}
.at-versionhistorycontent{height: calc(100vh - 250px);}
.at-versionhistorycontent .at-tableloader{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.AtAccesTokenList{
    width: 100%;
    overflow: auto;
    max-height: 200px;
}
.AtAccesTokenList::-webkit-scrollbar{width: 0;}
.at-accesstokenholder + .at-accesstokenholder{
    margin: 10px 0 0;
}
.toggle-ul.rotate-class:after{
    transform: rotate(-90deg);
}
.rt-primarycolor{color: #069FE3;}
.rt-tab{
        width: 100%;
        position: relative;
    .btn{
        gap: 10px;
        border: 0;
        padding: 0;
        width: 100%;
        color: #fff;
        display: flex;
        font-size: 12px;
        font-weight: 500;
        cursor: pointer;
        min-height: 54px;
        line-height: 15px;
        padding: 10px 12px;
        position: relative;
        border-radius: 4px;
        align-items: center;
        text-decoration: none;
        justify-content: flex-start;
        font-family: "Exo", sans-serif;
        background: rgba(255, 255, 255, 0.1);
        border: 1px solid rgba(255, 255, 255, 0.32);
    }
    .progress{
        left: 0;
        z-index: 2;
        width: 100%;
        height: 6px;
        bottom: -1px;
        position: absolute;
        border-radius: 0 0 4px 4px;
    }
}
.rt-labelholder{
    width: 100%;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.rt-rowgap{gap: 15px 0;}
.rt-accordionuploadarea{width: 100%;}
.rt-accordionsholder{
    gap: 20px;
    width: 100%;
    display: flex;
    align-items: flex-start;
}
.rt-sccurityaccorions{
    flex-grow: 1;
    margin: 0 0 30px;
}
.rt-accordiongroups{
    overflow: hidden;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.12);
    .block{
        background: none;
        justify-content: space-between;
        p{
            font-size: 16px;
            color: #069FE3;
            font-weight: bold;
            line-height: 18px;
        }
        .button--more{
            font-size: 14px;
            font-weight: 400;
            line-height: 14px;
        }
    }
    .card-body{
        padding: 0 16px 16px;
    }
}
.rt-codelockaccordions{
    flex-shrink: 1;
    min-width: 350px;
    max-width: 350px;
    .blocks__nist{
        .rt-accordiongroups{
            .card-body{
                padding: 0 16px 16px;
                .panel-body{
                    overflow: auto;
                    max-height: 180px;
                    &::-webkit-scrollbar {
                        width: 5px; /* Set width of the scrollbar */
                    }
                    &::-webkit-scrollbar-track {
                        background: #283c63;
                        border-radius: 10px;
                    }
                    &::-webkit-scrollbar-thumb {
                        background: rgb(91, 120, 179);
                        border-radius: 10px;
                    }
                }
            }
        }
    }
}
.rt-uploadarea{width: 100%;}
.rt-uploadhead{
    width: 100%;
    display: flex;
    margin: 0 0 16px;
    align-items: center;
    justify-content: space-between;
    h4{
        gap:10px;
        margin: 0;
        flex-shrink: 0;
        font-size: 16px;
        color: #069FE3;
        line-height: 19px;
        font-weight: bold;
    }
}
.rt-btnuploadfile{
    border: 0;
    color: #fff;
    display: flex;
    cursor: pointer;
    font-size: 14px;
    min-height: 28px;
    min-width: 116px;
    font-weight: bold;
    border-radius: 6px;
    align-items: center;
    background: #069FE3;
    justify-content: center;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -ms-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    &:hover{box-shadow: 0 0 6px #00b0fb;}
}
.rt-evidencearea,
.rt-evidanceholderarea{
    gap: 20px;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
}
.rt-evidanceholder,
.rt-evidenceartifact{
    overflow: hidden;
    padding: 10px 16px;
    position: relative;
    border-radius: 4px;
    min-height: 100px;
    background: rgba(255, 255, 255, 0.15);
    &:hover{
        .rt-btnactions{
            opacity: 1;
            visibility: visible;
        }
    }
}
.rt-evidenceartifactcontent{
    width: 100%;
    position: relative;
    div{
        margin: 0;
        width: 100%;
        h4{
            font-size: 12px;
            margin: 0 0 8px;
            font-weight: bold;
            line-height: 1.3;
        }
        p{
            margin: 0;
            font-size: 10px;
            overflow: hidden;
            line-height: 16px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;  
            color: rgba(255, 255, 255, 0.80);
        }
    }
    h5{
        font-size: 12px;
        margin: 0 0 8px;
        font-weight: bold;
        line-height: 1.3;
    }
    p{
        margin: 0;
        font-size: 10px;
        overflow: hidden;
        line-height: 16px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;  
        color: rgba(255, 255, 255, 0.80);
    }
}
.rt-commentsarea{
    width: 100%;
    margin: 25px 0 0;
    .rt-btncomment{
        width: 100%;
        color: #fff;
        display: flex;
        font-size: 14px;
        min-height: 48px;
        line-height: 16px;
        border-radius: 8px;
        position: relative;
        align-items: center;
        background: transparent;
        text-transform: capitalize;
        padding: 8px 100px 8px 16px;
        justify-content: flex-start;
        border: 1px solid #069FE3;
        span{
            top: 50%;
            right: 10px;
            display: flex;
            font-size: 14px;
            min-width: 80px;
            min-height: 30px;
            font-weight: 600;
            color: #010A29;
            border-radius: 6px;
            background: #fff;
            position: absolute;
            align-items: center;
            justify-content: center;
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);
        }
    }
}
.rt-commentsidebar{
    top: 0;
    opacity: 0;
    z-index: 99;
    right: -100%;
    height: 100%;
    width: 565px;
    position: fixed;
    visibility: hidden;
    padding: 24px 30px;
    background: #010A29;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -ms-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    &:after{
        top: 0;
        left: 0;
        z-index: 1;
        content: '';
        width: 100%;
        height: 100%;
        position: fixed;
        background: rgba(0, 0, 0, 0.70);
    }
}
.rt-commentsidebar.rt-hidden{
    right: 0;
    opacity: 1;
    visibility: visible;
}
.rt-detailsholder{
    z-index: 2;
    position: relative;
}
.rt-btnactions{
    top: 0;
    left: 0;
    gap: 4px;
    z-index: 3;
    opacity: 0;
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    visibility: hidden;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.45);
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -ms-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
}
.rt-btnaction{
    border: 0;
    height: 32px;
    color: #fff;
    display: flex;
    min-width: 32px;
    border-radius: 6px;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.12);
    backdrop-filter: blur(3px);
}
.rt-btndownload{
    gap:10px;
    font-size: 12px;
    padding: 0 10px;
    font-weight: 600;
    background: #069FE3;
}
.rt-commentnavtabs{
    gap: 30px;
    z-index: 2;
    width: 100%;
    display: flex;
    margin: 0 0 25px;
    padding: 0 0 20px;
    position: relative;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.rt-btncommenttab{
    border: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 400;
    background: none;
    text-transform: capitalize;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -ms-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    color: rgba(255, 255, 255, 0.80);
    &:hover{
        color: #fff;
        font-weight: bold;
    }
}
.rt-btncommenttab.rt-active{
    color: #fff;
    font-weight: bold;
}
.rt-commentdetails{
    width: 100%;
    overflow: auto;
    height: calc(100vh - 116px);
    &::-webkit-scrollbar{width: 0;}
}
.rt-detailcontent{
    width: 100%;
    h3{
        font-size: 16px;
        line-height: 1.3;
        color: #069FE3;
    }
}
.rt-description{
    width: 100%;
    p{
        font-size: 14px;
        line-height: 22px;
    }
    .rt-evidencearea{
        grid-template-columns: repeat(2, minmax(0, 1fr));
        .rt-evidenceartifact{
            .rt-btnaction{
                padding: 0;
                width: auto;
                height: auto;
                display: block;
                background: none;
            }
        }
    }
}
.rt-commentheading{
    width: 100%;
    margin: 0 0 24px;
    h4{
        color: #fff;
        line-height: 1;
        font-size: 20px;
        margin: 0 0 8px;
        font-weight: bold;
    }
    p{
        margin: 0;
        font-size: 12px;
        line-height: 16px;
        color: rgba(255, 255, 255, 0.72);
    }
}
.rt-writecommentarea{
    width: 100%;
    position: relative;
    form{
        width: 100%;
        position: relative;
        textarea{
            width: 100%;
            resize: none;
            height: 100px;
            color: #fff;
            font-size: 14px;
            border-radius: 4px;
            background: #1F2743;
            border: 1px solid #069FE3;
            padding: 15px 35px 40px 15px;
            &::-webkit-scrollbar{width: 0;}
        }
    }
}
.rt-btnsendmessage{
    border: 0;
    padding: 0;
    right: 10px;
    width: 24px;
    bottom: 15px;
    height: 24px;
    display: flex;
    position: absolute;
    border-radius: 4px;
    align-items: center;
    background: #069FE3;
    justify-content: center;
}
.rt-allcommentsarea{width: 100%;}
.rt-comments{
    width: 100%;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid rgba(255, 255, 255, 0.12);
    p{
        font-size: 12px;
        line-height: 22px;
        padding: 0 0 0 40px;
        color: rgba(255, 255, 255, 0.72);
    }
    textarea{
        border: 0;
        padding: 0;
        width: 100%;
        resize: none;
        color: #fff;
        font-size: 12px;
        background: transparent;
    }
}
.rt-comments + .rt-comments{margin: 4px 0 0;}
.rt-btncomments{
    gap: 6px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    button{
        border: 0;
        color: #fff;
        font-size: 12px;
        padding: 0 14px;
        min-width: 56px;
        font-weight: 600;
        min-height: 24px;
        border-radius: 6px;
        background: rgba(255, 255, 255, 0.12);
        -webkit-transition: all 300ms ease-in-out;
        -moz-transition: all 300ms ease-in-out;
        -ms-transition: all 300ms ease-in-out;
        -o-transition: all 300ms ease-in-out;
        transition: all 300ms ease-in-out;
        &:hover{
            color: #010A29;
            background: #fff;
        }
    }
}
.rt-activityarea{width: 100%;}
.rt-activitycontentarea{
    width: 100%;
    overflow: auto;
    height: calc(100vh - 185px);
    &::-webkit-scrollbar{width: 0;}
}
.rt-activities{
    padding: 0;
    width: 100%;
    list-style: none;
    line-height: normal;
    li{
        width: 100%;
        line-height: inherit;
        list-style-type: none;
    }
    li + li{margin: 16px 0 0;}
}
.rt-activity{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.rt-userimgname{
    gap: 8px;
    display: flex;
    align-items: center;
}
.rt-userumg{
    margin: 0;
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    overflow: hidden;
    border-radius: 50%;
    img{
        width: 100%;
        height: 100%;
        display: block;
        overflow: hidden;
    }
}
.rt-username{
    flex-shrink: 0;
    h4{
        color: #fff;
        margin: 0 0 5px;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
    }
    time{
        display: block;
        font-size: 12px;
        line-height: 12px;
        color: rgba(255, 255, 255, 0.72);
    }
}
.rt-activityoverview{
    gap: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h5{
        margin: 0;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.2;
    }
}
.rt-activitytag{
    color: #fff;
    display: flex;
    font-size: 12px;
    min-width: 80px;
    padding: 0 18px;
    min-height: 28px;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
}
.rt-pendingbg{
    background: #069FE3;
    color: #069FE3 !important;
}
.rt-completedbg{
    color: #028C41 !important;
    border-color: #028C41 !important;
}
.rt-btnclosesidebar{
    top: 22px;
    border: 0;
    padding: 0;
    z-index: 5;
    right: 30px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    position: absolute;
    background: rgba(255, 255, 255, 0.12);
    svg{
        width: 10px;
    }
}
.rt-sliderholder{
    width: 100%;
    h3{
        font-size: 14px;
        color: #069FE3;
        margin: 0 0 15px;
        font-weight: bold;
    }
}
.rt-carouselslider{
    padding: 0 0 8px;
    margin: 0 0 16px -8px;
    border-bottom: 1px solid rgba(217, 217, 217, 0.24);
    .slick-arrow{
        right: 0;
        top: -20px;
        left: auto;
    }
    .slick-prev{right: 25px;}
    .slick-list{
        overflow: initial;
        .slick-track{
            gap: 8px;
            display: flex;
            .slick-slide{
                gap: 10px;
                padding: 16px;
                display: flex;
                cursor: pointer;
                border-radius: 6px;
                position: relative;
                align-items: center;
                justify-content: space-between;
                background: rgba(255, 255, 255, 0.12);
                p{
                    margin: 0;
                    font-size: 12px;
                    color: #C4D8F4;
                    overflow: hidden;
                    line-height: 18px;
                    cursor: pointer;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    strong{color: #fff;}
                }
                &:hover{
                    background: rgba(255, 255, 255, 0.24);
                }
            }
        }
    }
    .slick-current{
        background: #fff !important;
        border-radius: 6px 6px 0 0 !important;
        &:after{
            left: 0;
            content: '';
            width: 100%;
            height: 8px;
            bottom: -8px;
            position: absolute;
            background: #fff;
        }
        p{
            color: #3D3D3D !important;
            strong{color: #010A29 !important;}
        }
    }
}
.rt-btnpendingcomlt{
    color: #069FE3;
    font-size: 12px;
    padding: 0 10px;
    min-width: 72px;
    max-height: 25px;
    font-weight: 600;
    background: none;
    border-radius: 6px;
    border: 1.5px solid #069FE3;
}
.rt-evidanceholderarea{
    // overflow: hidden;
    // padding: 10px 16px;
    // position: relative;
    // border-radius: 4px;
    // min-height: 100px;
    // background: rgba(255, 255, 255, 0.15);
    > div{
        width: 100%;
        overflow: hidden;
        min-height: 100px;
        padding: 10px 16px;
        position: relative;
        border-radius: 4px;
        margin: 0 !important;
        background: rgba(255, 255, 255, 0.15);
        h4{
            font-size: 12px;
            margin: 0 0 8px;
            font-weight: bold;
            line-height: 1.3;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            word-break: break-word;
            -webkit-box-orient: vertical;  ;
        }
        p{
            margin: 0;
            font-size: 10px;
            overflow: hidden;
            line-height: 16px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;  
            color: rgba(255, 255, 255, 0.80);
        }
        &:hover{
            .button-group{
                opacity: 1;
                visibility: visible;
            }
        }
    }
    .button-group{
        top: 0;
        left: 0;
        gap: 4px;
        z-index: 3;
        opacity: 0;
        width: 100%;
        height: 100%;
        display: flex;
        position: absolute;
        visibility: hidden;
        align-items: center;
        margin: 0 !important;
        justify-content: center;
        background: rgba(0, 0, 0, 0.45);
        -webkit-transition: all 300ms ease-in-out;
        -moz-transition: all 300ms ease-in-out;
        -ms-transition: all 300ms ease-in-out;
        -o-transition: all 300ms ease-in-out;
        transition: all 300ms ease-in-out;
        a{
            border: 0;
            height: 32px;
            color: #fff;
            display: flex;
            min-width: 32px;
            border-radius: 6px;
            align-items: center;
            margin: 0 !important;
            justify-content: center;
            background: rgba(255, 255, 255, 0.12);
            backdrop-filter: blur(3px);
        }
    }
}
.rt-commenthead{
    width: 100%;
    display: flex;
    margin: 0 0 10px;
    align-items: center;
    justify-content: space-between;
    .rt-userumg{
        width: 24px;
        height: 24px;
    }
}
.rt-editdeldropdown{
    .dropdown-toggle{
        &:after{display: none;}
        svg{
            path{stroke: #676C7F;}
        }
    }
    .dropdown-menu{
        padding: 20px 24px;
        border-radius: 8px;
        right: 0 !important;
        left: auto !important;
        li{
            button{
                border: 0;
                padding: 0;
                color: #000;
                font-size: 14px;
                font-weight: 600;
                background: none;
                line-height: 14px;
            }
        }
        li + li{
            margin: 12px 0 0;
            button{color: #FF0000;}
        }
    }
}
.rt-modaldelete{
    max-width: 315px;
    .modal-body{
        padding: 32px;
        background: #fff;
        border-radius: 16px;
        h3{
            font-size: 24px;
            margin: 0 0 6px;
            color: #2E2E2E;
            line-height: 24px;
            font-weight: bold;
        }
        p{
            font-size: 16px;
            color: #8A8A8A;
            line-height: 20px;
        }
    }
}
.rt-btnsmodal{
    gap: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button{
        border: 0;
        width: 50%;
        display: flex;
        font-size: 14px;
        min-height: 32px;
        font-weight: 600;
        border-radius: 6px;
        align-items: center;
        justify-content: center;
    }
    .btn-primary{
        color: #303030;
        background: #D9D9D9;
        &:hover{background: #c4c2c2;}
    }
    .btn-default{
        color: #fff;
        background: #E24329;
        &:hover{background: #bc331d;}
    }
}
.rt-uploadmodal{
    .modal-dialog{
        max-width: 400px;
        .modal-content{
            .modal-header{padding: 20px 20px 0;}
            .modal-body{
                p{
                    font-size: 12px;
                    margin: 0 0 15px;
                    line-height: 15px;
                }
                .box-upload{
                    padding: 20px;
                    margin: 0 0 10px;
                    min-height: 100px;
                    align-items: center;
                    justify-content: center;
                    .button{
                        display: flex;
                        padding: 0 10px;
                        font-size: 14px;
                        min-width: 128px;
                        min-height: 32px;
                        align-items: center;
                        justify-content: center;
                        background: #069FE3 !important;
                    }
                }
            }
        }
    }
}
.rt-file-preview{
    width: 100%;
    padding: 10px;
    margin: 0 0 16px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.05);
}
.rt-filetitle{
    width: 100%;
    label{
        font-size: 12px;
        margin: 0 0 12px;
        font-weight: 400;
    }
    .form-textarea{
        height: 100px;
        font-size: 12px;
        box-shadow: none;
        font-weight: 400;
        line-height: 20px;
        padding: 12px 14px;
        border-color: #069FE3;
    }
}
.btn-delete-file{
    right: 0;
    top: 50%;
    border: 0;
    width: 9px;
    padding: 0;
    height: 9px;
    background: none;
    box-shadow: none;
    position: absolute;
    margin: 0 !important;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    &:hover{box-shadow: none;}
    &:after{
        top: 0;
        right: 0;
        width: 9px;
        height: 9px;
        content: "";
        position: absolute;
        background: url(/assets/images/icon__close.svg) no-repeat;
        background-size: contain;
    }
}
.rt-btnsubmit{
    border: 0;
    font-size: 14px;
    min-width: 116px;
    min-height: 28px;
    font-weight: bold;
    color: #fff !important;
    box-shadow: none !important;
    padding: 6px 10px !important;
    background: #069FE3 !important;
}
.rt-selectreport{
    gap: 30px;
    width: 100%;
    display: flex;
    margin: 0 0 24px;
    align-items: center;
}
.rt-radio{
    input[type=radio]{display: none;}
    input[type=radio] + label{
        display: flex;
        color: #fff;
        font-size: 12px;
        cursor: pointer;
        font-weight: 400;
        line-height: 16px;
        position: relative;
        align-items: center;
        padding: 0 0 0 24px;
        &:before{
            left: 0;
            top: 50%;
            width: 16px;
            content: '';
            height: 16px;
            position: absolute;
            border-radius: 50%;
            background: transparent;
            border: 1px solid #069FE3;
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);
        }
    }
    input[type=radio]:checked + label{
        &:before{
            border-width: 4px;
            background: #fff;
        }
    }
}
.rt-downloaddropdown{
    a{
        svg{
            path{stroke: #fff !important;}
        }
    }
}
.rt-templates{
    gap: 12px;
    display: grid;
    align-items: flex-start;
    grid-template-columns: repeat(2, minmax(0, 1fr));
}
.rt-templateBox{
    width: 100%;
    border-radius: 4px;
    position: relative;
    padding: 12px 42px 12px 12px;
    background: rgba(255, 255, 255, 0.12);
}
.rt-templateHead{
    h2{
        margin: 0;
        font-size: 14px;
        margin: 0 0 12px;
        overflow: hidden;
        color: #069FE3;
        font-weight: bold;
        line-height: 20px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}
.rt-templatebody{
    p{
        margin: 0;
        font-size: 12px;
        font-weight: 500;
        overflow: hidden;
        line-height: 20px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
}
.rt-btndownloadTemplate{
    top: 0;
    right: 0;
    border: 0;
    padding: 0;
    z-index: 2;
    width: 30px;
    height: 100%;
    display: flex;
    position: absolute;
    align-items: center;
    background: #069FE3;
    justify-content: center;
    border-radius: 0 4px 4px 0;
}
.rt-btnopencomment{padding: 0.5rem;}
@media (max-width: 1280px){
    .rt-codelockaccordions {
        min-width: 280px;
        max-width: 280px;
    }
}






