/////////////////////////
// Tab
/////////////////////////

.tab-container {
    .nav-tabs {
        border: none;
        padding: 1rem 1.5rem;
        background: linear-gradient(to bottom, #0e3c7c 0%, rgba(14, 60, 124, 0) 100%);
        @include radius(0.5rem 0.5rem 0 0);

        li {
            & + li {
                margin-left: 2rem;
            }
        }

        .nav-link {
            border: none;
            margin: 0;
            @include radius(0px);
            padding: 0;
            @include font__size(13px);
            @include line__height(20px);
            color: $color-white;
            font-weight: $font-weight--700;

            &.active {
                color: $color-primary;
                background: none;
            }
        }
    }

    .tab-content {
        padding: 2rem 0 0;
        @include font__size(13px);
        @include line__height(20px);
    }

    &.tab-compact {
        .tab-content {
            padding-top: 0;

            .table-main {
                @include radius(0);
            }
        }
    }
}